<template>
  <page-item collection="news" />
</template>

<script>
import PageItem from '@/components/items/Page'

export default {
  name: 'NewsPage',
  components: {
    PageItem,
  },
};
</script>
